/* File: src/components/Help.css */
.help-section {
  padding: 60px;
  /* Dark background similar to the homepage */
  color: #f4f4f4;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.help-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #0c0c0b; /* Match the color of Ethiopian Women's Lawyers Association (EWLA) */
}

.help-section p.intro-text {
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center; /* Center the introductory text */
  color: #0c0c0b; /* Match the color of Ethiopian Women's Lawyers Association (EWLA) */
  margin-bottom: 40px; /* Add more space below the intro text */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.help-card {
  background-color: #333; /* Darker card background for contrast */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  border: 1px solid #444; /* Slightly darker border */
  width: 100%;
  max-width: 350px;
  text-align: left;
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth effects */
}

.help-card:hover {
  transform: translateY(-10px);
  background-color: #444; /* Lighten the card on hover */
}

.help-card h3 {
  font-size: 1.6rem;
  margin-top: 0;
  color: #f4c542; /* Accent color for the card titles */
  margin-bottom: 15px;
}

.help-card p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #dcdcdc; /* Lighter text for readability */
}

.help-card a {
  color: #1e90ff;
  text-decoration: none;
}

.help-card a:hover {
  text-decoration: underline;
  color: #1c75d8;
}

