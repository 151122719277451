/* Awareher.css */

.awareher-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .havan-story {
    margin-top: 40px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .havan-image img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .havan-description h3 {
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  