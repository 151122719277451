/* Navbar container */
.navbar {
  background-color: #2c3e50;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navbar Logo */
.navbar-logo {
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  margin-left: 20px;
}

/* Navbar Links */
.navbar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.navbar-links li {
  margin-right: 10px;
}

.navbar-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #ff7f50;
}

/* Donate Button Styling */
.donate-link {
  background-color: #ff7f50;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.donate-link:hover {
  background-color: #ff5722;
}

/* Hamburger Icon */
.hamburger {
  display: none;
  font-size: 1.8rem;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
  .navbar-links {
    display: none; /* Hide navbar links on small screens */
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #2c3e50;
    width: 100%;
    padding: 10px 0;
  }

  .navbar-links.mobile-menu.open {
    display: flex; /* Show navbar links when menu is open */
  }

  .navbar-links li {
    text-align: center;
    margin: 10px 0;
  }

  .hamburger {
    display: block; /* Show hamburger icon */
  }
}
